import { useContext } from "react";
import { IProduct } from "../../models/interfaces";
import { IShopContext, ShopContext } from "../../context/shop-context";
import { useNavigate } from "react-router-dom";

interface Props {
    product: IProduct;
    quantity: number;
    size: string;
    isCheckout?: boolean; 
}

export const CartItem: React.FC<Props> = (props: Props) => {
    const { _id, imageURL, productName, price } = props.product;
    const { isCheckout, size} = props;
    const { addToCart, removeFromCart, getCartItemCount } = useContext<IShopContext>(ShopContext);
    const cartItemCount = getCartItemCount(_id, size);
    const navigate = useNavigate();

    
    return (
        /* PAYMENT PAGE  */
        <div className="cart-item">
            { isCheckout === true ? ( // CART PAGE 
            <div className="cart-item-content">
                <img src={imageURL} alt={productName} className="cart-item-image" />
                <div className="description">
                    <p>x{cartItemCount} {productName} {size} {price}</p>
                </div>
            </div>
                    ) : (
                        <div className="cart-item-content">
                            <img src={imageURL} className="cart-item-image" onClick={() => navigate(`/product/${productName}`)}/>
                            <div className="description"> 
                                <p>NAME: {productName}</p>
                                <p>PRICE: €{price}</p>
                                <p>SIZE: {size}</p>
                            </div>
                            <div className="count-handler">
                                <button disabled={isCheckout} onClick={() => removeFromCart(_id, size)}> - </button>
                                <p> {cartItemCount}</p>
                                <button disabled={cartItemCount >= 9 || isCheckout} onClick={() => addToCart(_id, size)}> + </button>
                            </div>
                        </div>
             )}
        </div>
    );
};
