import { useCallback, useEffect, useState } from "react";
import axios from "axios";

import { IProduct } from "../models/interfaces";


export const useGetProducts = () => {
    const [products, setProducts] = useState<IProduct[]>([]);


    const fetchProducts = useCallback (async () => {
        try {
        const fetchedProducts = await axios.get("https://api.xt13.com/product");
        setProducts(fetchedProducts.data.products)
        } catch (err) {
            alert("ERROR: SOMETHING WENT WRONG.");
        }; // Revision ++
    
    }, []);

    useEffect(() => {
        fetchProducts();

    }, [])

    return { products };
}


