import { createContext, useEffect, useState } from "react";
import { useGetProducts } from "../hooks/useGetProducts";
import { IProduct } from "../models/interfaces";
import axios from "axios";
import { useGetToken } from "../hooks/useGetToken";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';


export interface IShopContext {
    products: IProduct[];
    addToCart: (itemId: string, size: string ) => void;
    removeFromCart: (itemId: string, size: string) => void;
    updateCartItemCount: (newAmount: number, itemId: string) => void;
    getCartItemCount: (itemId: string, size: string) => number;
    getTotalCartAmount: () => number;
    isAuthenticated: boolean;
    setIsAuthenticated: (isAuthenticated: boolean) => void;
    getCartItems: () => { productId: string, productName: string, quantity: number, size: string }[];
    clearCart: () => void,


}

const defaultVal: IShopContext = {
    clearCart: null,
    addToCart: () => null,
    removeFromCart: () => null,
    updateCartItemCount: () => null,
    getCartItemCount: () => 0,
    getTotalCartAmount: () => null,
    isAuthenticated: false,
    setIsAuthenticated: () => null,
    getCartItems: () => null,
    products: null,
};

export const ShopContext = createContext<IShopContext>(defaultVal)



export const ShopContextProvider = (props) => {
    const navigate = useNavigate();
    const [ cartItems, setCartItems ] = useState<{ productId: string, quantity: number, size: string, productName: string }[]>([]);
    const { products } = useGetProducts();
    const {headers} = useGetToken();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)


    useEffect(() => {
        const savedCartItems = Cookies.get('cartItems');
        if (savedCartItems) {
            setCartItems(JSON.parse(savedCartItems));
        }
    }, []);

    useEffect(() => { /* Update cookies if the cart changes */
        Cookies.set('cartItems', JSON.stringify(cartItems), { expires: 7});
    }, [cartItems]);

    const getCartItems = () => cartItems;
    
    const addToCart = ( itemId: string, size: string ) => {
        setCartItems(prevCartItems => {
            const itemIndex = prevCartItems.findIndex(item => item.productId === itemId && item.size === size);
            const product = products.find(p => p._id === itemId);

            if (!product) {
                return prevCartItems;
            }

            if (itemIndex !== -1) {
                const updatedCartItems = [...prevCartItems];
                updatedCartItems[itemIndex].quantity += 1;
                return updatedCartItems;
            } else {
                return [
                    ...prevCartItems, 
                    { productId: itemId, quantity: 1, size, productName: product.productName }
                ];
            }
        });
    };

    const removeFromCart = ( itemId: string, size: string) => {
        setCartItems(prevCartItems =>
            prevCartItems
            .map(item => item.productId === itemId && item.size === size
                ? {...item, quantity: item.quantity - 1}
                : item)
            .filter(item => item.quantity > 0) 
        )
    };

    const clearCart = () => {
        setCartItems([]);
    };

    const getCartItemCount = (itemId: string, size: string): number => {
        const item = cartItems.find(item => item.productId === itemId && item.size === size);
        return item ? item.quantity : 0;
    };


    const updateCartItemCount = ( newAmount: number, itemId: string ) => {
        if (newAmount < 0) return;
        setCartItems((prev) => ({...prev, [itemId]: newAmount}));

    };

    const getTotalCartAmount = (): number => { // Subtotal price calculation.
        let totalAmount = 0;

        cartItems.forEach(item => {
            const product = products.find(product => product._id === item.productId);
            if (product) {
                totalAmount += item.quantity * product.price;
            }
        })
        return totalAmount;
    };

    

    const contextValue: IShopContext = {
        clearCart,
        addToCart,
        removeFromCart,
        updateCartItemCount,
        getCartItemCount,
        getTotalCartAmount,
        isAuthenticated,
        setIsAuthenticated,
        getCartItems,
        products,
    };

    return (
    <ShopContext.Provider value={contextValue}>
        {props.children}
    </ShopContext.Provider>
    );
};