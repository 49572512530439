
import { useParams } from "react-router-dom";
import "./summary.css";
import { useCallback, useContext, useEffect, useState } from "react";
import { IShopContext, ShopContext } from "../../context/shop-context";
import { IProduct } from "../../models/interfaces";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";



interface Props {
    product: IProduct
};

const configUrl = process.env["PRODUCTION_CONFIG_URL"];

export const SummaryPage = ( props: Props ) => {
    const { orderID } = useParams();
    const { clearCart } = useContext<IShopContext>(ShopContext);
    const navigate = useNavigate();
    const [stripePromise, setStripePromise] = useState(null);

    const fetchStripeConfig = useCallback(async () => {
        try {
            const res = await fetch(`${configUrl}`);
            const data = await res.json();
            setStripePromise(loadStripe(data.publishableKey));
        } catch (e) {
            console.log("Error fetching stripePromise: ", e);
        }
    }, []);


    useEffect(() => {
        fetchStripeConfig();

        if (!stripePromise) {
            return;
        };
          
            stripePromise.then(async (stripe) => {
                try {
                    const url = new URL(window.location.href);
                    const clientSecret = url.searchParams.get("payment_intent_client_secret")

                    if (!clientSecret || clientSecret == null) {
                        navigate('/');
                    } else {
                        const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
                            /* returns result / error */
                        console.log("paymentIntent status is ", paymentIntent.status);
                        if (paymentIntent.status !== "succeeded" || paymentIntent.error) {
                            navigate('/'); 
                        }
                    }

                } catch (e) {
                    console.log(e);
                }
            })

    }, [])

    useEffect(() => {
        Cookies.remove('cartItems');
        clearCart();
    }, [])

    return (
        <div className="summary-container">
                <div className="paragraph-body"> 

                        <h1>ORDER ID: {orderID} </h1> <br/>
                        Thank you for your purchase! <br/>
                        You will receive an e-mail in the shortest amount of time regarding order updates. <br/>
                
                        Please allow up to 1 week for order processing. We will use the order ID to update the order status. <br/>

                      <p> For any inquiries, or if you require further assistance, please contact support@xt13.com</p>

                      <p> Sincere thanks, <br/> ATARAXIA</p>
                      
                </div>

        </div>
    )

}