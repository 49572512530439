import React, { useEffect, useState } from "react";
import { Intro } from "../../components/intro";
import { useGetProducts } from "../../hooks/useGetProducts";
import { Product } from "./product";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import whitewall from "../../components/assets/whitewall.png"

export const ShopPage = () => {
    const { products } = useGetProducts();
    const [showIntro, setShowIntro] = useState(true);
    const navigate = useNavigate();
 

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowIntro(false);
        }, 500); // Adjust the timeout as needed

        return () => clearTimeout(timer);
    }, []);

    return (
            
      
        <div className="shop">

            <div className={`intro-overlay ${showIntro ? "show" : "hide"}`}>
                <Intro />
            </div>
            <div className={`products ${showIntro ? "fade-out" : "fade-in"}`}>
                {products.map((product) => (
                    <Product product={product} key={product._id} />
                ))}
            </div>
            <div className="manifesto" onClick={() => navigate("/ethos")}>
                <img
                src={whitewall} /* background image */
                />
                <p id="text">ETHOS</p>
            </div>

        </div>

      
    );
};