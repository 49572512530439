import { useState } from "react";
import { Carousel } from "react-bootstrap";

export const ProductCarousel = ({ image, image2 }) => {
    const [index, setIndex] = useState(0);
    
    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    }

    return (
        <Carousel 
        activeIndex={index} 
        onSelect={handleSelect}
        data-interval=""
        >
            <Carousel.Item key="1">
                <img src={image}/>
            </Carousel.Item>

            <Carousel.Item key="2">
                <img src={image2}/>
            </Carousel.Item>
        </Carousel>
    )
}