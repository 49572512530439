import { SyntheticEvent, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { UserErrors } from '../../models/errors';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import "./styles.css";

export const RegisterPage = () => {

    return (<div className="register">
         <Register />

    </div>
    );
};


const Register = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (event: SyntheticEvent) => {
        event.preventDefault();
        try {
        await axios.post("http://localhost:3001/user/register", {username, password});
        alert("REGISTRATION COMPLETED!");
        navigate("/login");

        } catch (err) {
            if (err?.response?.data?.type === UserErrors.USERNAME_ALREADY_EXISTS) {
                alert("ERROR: USERNAME ALREADY IN USE.")
            } else {
                alert("ERROR: SOMETHING WENT WRONG.")
            }
        }
    };

    return (
        <div className="auth-container">
         <form onSubmit={handleSubmit}>
            <h2> REGISTER </h2>
            <div className="form-group">
                <label htmlFor="username"> USERNAME:</label>
                <input
                 type="text" 
                 id="username" 
                 value={username}
                 onChange={(event) => setUsername(event.target.value)}/>
            </div>

            <div className="form-group">
                <label htmlFor="password"> PASSWORD:</label>
                <input 
                type="password" 
                id="password" 
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                />
            </div>
            <div className='submitbutton'>
            <button type="submit"> REGISTER </button>
            </div>
        </form> 
    </div>
    );
};

