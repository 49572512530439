import { Link, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { IShopContext, ShopContext } from '../context/shop-context';
import { useContext, useEffect, useRef, useState } from 'react';
import "./navbarStyles.css";
import { CartPage } from '../pages/cart';
import xicon from "./assets/x-icon.png";

export const Navbar = () => {
    // const { isAuthenticated, setIsAuthenticated } = useContext<IShopContext>(ShopContext);
    // const [_, setCookies] = useCookies(["access_token"]);
    // const logout = () => {
    //     localStorage.clear();
    //     setCookies("access_token", null);
    //     setIsAuthenticated(false);
    // }
    const { getCartItems } = useContext<IShopContext>(ShopContext);
    const cartItems = getCartItems();
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const location = useLocation();
    const isCheckout = location.pathname.includes("checkout");
    



    const toggleDropDown = () => {
        setIsOpen(!isOpen)
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false)
        }
    }

    const handleLinkClick = () => {
        setIsOpen(false);
    }

    useEffect(() => {
        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
        
    }, [isOpen]);


    while (!isCheckout) {
    return (
    <div id="navbar" className='navbar'> 
            <div className="navbar-links">
            <div id='cart-button' onClick={toggleDropDown}> 
                    [{cartItems.length}]
                </div>
                <Link to="/" id='home-button'> HOME </Link>
                

                {isOpen && (
                    <div className='cart-container' ref={dropdownRef}> 
                        <button className='close-cart' onClick={toggleDropDown}>
                            <img src={xicon} id='x-icon' />
                        </button>
                    <CartPage onClose={handleLinkClick}/>
                    </div>
                )}

            </div>
        </div>
    )
}}