

import React from "react";
import "./styles.css";


export const Manifesto = () => {
    // ABOUT US PAGE
    
    return ( 
    
        <div className="text-container">
            <div className="text-body"> 
                <p> 
                ESTABLISHED IN 2021, THE HEXADECIMAL IDENTIFICATOR "ATARAXIA" REPRESENTS PERFECT IMPERFECTION, <br/>
                SYNTHETICALLY BORN IN THE INDUSTRIAL WORLD, GEOMETRICALLY CONSTRUCTED ANARCHY. <br/>

                2021 BUCHAREST, ROMANIA [Established] <br/>
                [Materialized] in the industrial world in 2024 <br/>
                <br/>
                XT-13 was brought into existence after undergoing TECHNICAL metamorphosis;

                </p>
            </div>
         
        </div>
    )
}

