import { useContext, useEffect, useRef, useState } from "react";
import { useGetProducts } from "../../hooks/useGetProducts";
import { IProduct } from "../../models/interfaces";
import { IShopContext, ShopContext } from "../../context/shop-context";
import { CartItem } from "./cart-item";
import "./styles.css";
import { Link, useNavigate } from "react-router-dom";
import { randomOrderGen } from "../payment/orderId";

export const CartPage: React.FC<{ onClose: () => void}> = ({ onClose }) => {
    const { getCartItemCount, getTotalCartAmount, getCartItems } = useContext<IShopContext>(ShopContext);
    const { products } = useGetProducts();
    const totalAmount = getTotalCartAmount();
    const navigate = useNavigate();
    const cartItems = getCartItems();
    const orderID = randomOrderGen();

    return (
    <div className="cart"> 
        <div className="cart-item">
            {cartItems.map(({productId, size, quantity}) => {
                const product: IProduct | undefined = products.find(p => p._id === productId);
                return product && (
                    <CartItem
                    key={`${productId}-${size}`}
                    product={product}
                    quantity={quantity}
                    size={size}
                    isCheckout={false}

                    />
                )})}
        </div>
        {totalAmount > 0 ? (
        <div className="checkout"> 
            <p>SUBTOTAL: €{totalAmount}</p>
            <div className="checkout-links">
            <Link to={`/checkout/${orderID}`} onClick={onClose}> CHECKOUT </Link>   
            </div>
        </div>
        ) : (
        <div className="empty-container">
            <p id="empty-text">Your shopping cart is empty.</p>
        </div>
        )}
     </div>
    );

}
