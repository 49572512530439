import React, { useState } from "react"
import { LinkAuthenticationElement } from "@stripe/react-stripe-js";



export const EmailForm = () => {
    const [ email, setEmail ] = useState("");


    
    // const handleEmail = (event) => {
    //     if (event.complete) {
    //         setEmail(event.value.email);
    //     }
    // }


    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     try {
    //         const res = await fetch("http://localhost:3001/customer-object", {
    //             method: "POST",
    //             headers: { 'Content-Type': 'application/json' },
    //             body: JSON.stringify({ email })
    //         });
    //     } catch (error) {
    //         console.log(error)            
    //     }
    // }


    return (
        <form className="email-form" >
            <LinkAuthenticationElement/>

        </form>
        
    )
}