import { useContext, useEffect, useState } from "react";
import { ShopContext, IShopContext } from "../../context/shop-context";
import { IProduct } from "../../models/interfaces";
import { useParams } from "react-router-dom";
import "./styles.css";
import 'react-toastify/dist/ReactToastify.css';
import { ProductCarousel } from "./carousel";
import './carousel.css';
import { snackbarFunction } from "./snackbar";


export const ProductPage = () => {
    const { productName } = useParams<{ productName: string }>();
    const { products, addToCart } =  useContext<IShopContext>(ShopContext);
    const [ product, setProduct ] = useState<IProduct | null>(null);
    const [ selectedSize, setSelectedSize ] = useState("S");
    const [ selectedIndex, setSelectedIndex ] = useState<number | null>(0);


    useEffect(() => {
        if (products.length > 0) {
            const foundProduct = products.find(p => p.productName === productName);
            setProduct(foundProduct || null);  
        } 
    }, [products, productName]);

    const handleSizeClick = (size: string, index: number) => {
        setSelectedSize(size);
        setSelectedIndex(index);    
    }

    const sizes = ["S", "M", "L", "XL"];

    if (!product) {
        return ( <div> NO PRODUCT FOUND! </div>)
    }
   
    return (
        <div className="product-page"> 
            <div className="product-image-container">
                <ProductCarousel 
                image={product.imageURL} 
                image2={product.imageURL2}
                />
 
            </div>
            <div className="product-details">
                <p>{product.productName}</p>
                <p>€{product.price}</p>

            </div>
                <div id="snackbar">
                    ITEM ADDED TO CART
                </div>
                <div className="size-buttons">
                    { sizes.map((size, index) => (
                        <button
                        key={size}
                        className={`size-button ${selectedSize === size ? 'selected' : ''}`}
                        onClick={() => handleSizeClick(size, index)}
                        > 
                            {size}

                        </button>
                    ))}
                </div>
                    <div className="second-button">

                        <button
                        className="add-to-cart-bttn"
                        disabled={!selectedSize}
                        onClick={() => { selectedSize && addToCart(product._id, selectedSize); snackbarFunction() }}>
                                ADD TO CART
                            </button>
                    </div>

            <div className="care-container">
                <div id="product-details-text">
                    <p> 
                        PRODUCT DETAILS: 
                        <br/>
                        <br/>

                        &emsp; MATERIALS: 100% FRENCH TERRY COTTON PRESHRUNK 3% <br/>
                        &emsp; WEIGHT: 220 GSM <br/>
                        <br/>
                        &emsp; CARE: WASH & DRY INSIDE-OUT; COLD WASH OR DO NOT EXCEED 30C;<br/> NO BLEACH; NO TUMBLE DRY IN HOT AIR; DRY IN THE SHADE.
                    </p>

                    <p>
                        DIMENSIONS:
                        <br/>
                        <br/>
                        &emsp; PIT TO PIT ({selectedSize}
                           ) {product.pitDimensions[selectedIndex]} <br/>
                        &emsp; COLLAR TO HEM ({selectedSize}) {product.hemDimensions[selectedIndex]} <br/>
                    </p>
                </div>

            </div>        
        </div>
        
    )
}