
import { IProduct } from "../../models/interfaces";
import { useNavigate } from "react-router-dom";
import "./styles.css";

interface Props {
    product: IProduct;
}

export const Product = (props: Props) => {
    const { _id, productName, stockQuantity, imageURL, price } = props.product;
    const navigate = useNavigate();

    const handleProductClick = () => {
        navigate(`/product/${productName}`);
    }


    return (
        <div className="product">
            <div className="product-image-container" >
                <img
                onClick={handleProductClick}
                key={_id} className="product-image-page" src={imageURL} />
                <div className="description">
                    {stockQuantity === 0 ? (
                        <p>SOLD OUT</p>
                    ) : (
                        <p>{productName} <br/> €{price}</p>
                    )}
                </div>
            </div>
        </div>
    )
}
