import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navbar } from './components/navbar';
import { ShopPage } from './pages/shop';
import { RegisterPage } from './pages/register'; 
import { ShopContextProvider } from './context/shop-context';
import { ProductPage } from './pages/product';
import "./App.css";
import { Completion } from './pages/completion/completion';
import Payment from './pages/payment/Payment';
import NotFound from './pages/notFound';
import { Manifesto } from './pages/manifesto/manifseto';



function App() {


  return (
    <div className='App'>
        <Router>
          <ShopContextProvider>
            <Navbar />
            <Routes>

              <Route path="/" element={<ShopPage />} />            
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/product/:productName" element={<ProductPage />} />
              <Route path="/checkout/:orderID" element={<Payment />} />
              <Route path="/completion/:orderID" element={<Completion />} />
              <Route path='/ethos' element={<Manifesto />} />
              <Route path="*" element={<NotFound />} />
              

            </Routes>
          </ShopContextProvider>
        </Router>
      <div className='footer'>
        <div id='rights'>
          XT-13 2024 \ ALL RIGHTS RESERVED
        </div>
      </div>
    </div>
  );
}

export default App;
