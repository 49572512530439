import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SummaryPage } from "./purchaseSummary";

/* Redirect page */

export const Completion = (props) => {

    return (
        <>
            <SummaryPage/>
        </>
    )
}