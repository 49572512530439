import { useEffect } from "react";
import "./intro.css";

export const Intro = () => {


    return (
        <div className="logo">
            X T - 1 3
        </div>
    );
};

