import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import "./PaymentForm.css";
import { useParams } from "react-router-dom";


export default function PaymentForm() {
    const [ isLoading, setIsLoading] = useState(false);
    const [ message, setMessage ] = useState("");
    const stripe = useStripe();
    const elements = useElements();
    const { orderID } = useParams();

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);
        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/completion/${orderID}`,
            }});

            if (error.type === "card_error" || error.type === "validation_error") {
                setMessage(error.message);
            } else {
                setMessage("An unexpected error has occured. Try again later.");
            };
            setIsLoading(false);
         
    
    };
    
    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement/>
            <button disabled={isLoading} id="submit">
                <span className="button-text">
                    {isLoading ? <div className="spinner" id="spinner">LOADING</div> : "PURCHASE"}
                </span>
            </button>
            {message && <div id="payment-message">{message}</div>}
        </form>
    )
}

